import { useState, useCallback } from 'react';
import { dealerProvider } from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface ValidationResult {
  success: boolean;
  message?: string;
}

export const useValidations = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [emailStatus, setEmailStatus] = useState<
    'unvalidated' | 'validated' | 'error' | 'pending'
  >('unvalidated');
  const [validationStep, setValidationStep] = useState<1 | 2>(1);
  const [email, setEmail] = useState<string>('');
  const [validationCode, setValidationCode] = useState<string>('');
  const navigate = useNavigate();
  const solicitaEmailValidacao = useCallback(
    async (email: string): Promise<ValidationResult> => {
      setLoading(true);
      setError(null);

      const momentoRequisicao = new Date().toISOString();
      sessionStorage.setItem('validationMoment', momentoRequisicao);

      try {
        const response = await dealerProvider.post(
          '/api/solicita-email-validacao',
          { email, momentoRequisicao },
        );

        if (response.status !== 200) {
          return { success: false, message: response.data.data };
        }
        setEmailStatus('pending');
        return response.data.data;
      } catch (err: any) {
        setError(
          err.response.data.errors ||
            'Não foi possível gerar o código, tente novamente.',
        );
        return { success: false, message: err.response.data.errors };
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const validaEmailValidacao = useCallback(
    async (
      momentoRequisicao: string,
      codigoValidacao: string,
      email: string,
    ): Promise<ValidationResult> => {
      setLoading(true);
      setError(null);

      try {
        const response = await dealerProvider.post(
          '/api/valida-email-validacao',
          { codigoValidacao, email, momentoRequisicao },
        );

        if (response.status !== 200) {
          toast.error('Erro ao validar código de validação');
          return {
            success: false,
            message: 'Erro ao validar código de validação',
          };
        }

        setEmailStatus('validated');
        return response.data.data;
      } catch (err: any) {
        setError(
          err.response.data.errors ||
            'Não foi possível validar o código, tente novamente.',
        );
        return { success: false, message: err.response.data.errors };
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const cadastraRevendedorValidado = useCallback(
    async (informations: any): Promise<ValidationResult> => {
      const signupDealer = {
        ...informations,
        inscricaoEstadual: informations.stateRegistration,
        razaoSocial: informations.corporateName,
        nomeFantasia: informations.tradeName,
        endereco: informations.logradouro,
        uf: informations.estado,
        senha: '',
        email: informations.emailData,
      };

      try {
        const response = await dealerProvider.post(
          '/api/cadastrar',
          signupDealer,
        );

        return {
          success: true,
          message: 'Pré-cadastro realizado com sucesso!',
        };
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          console.error('Erro 400: ', error.response.data.errors[0]);
          return {
            success: false,
            message: error.response.data.errors[0],
          };
        }

        return {
          success: false,
          message: 'Erro inesperado ao realizar o cadastro.',
        };
      }
    },
    [],
  );

  const handleEmailSubmit = async (email: string) => {
    const result = await solicitaEmailValidacao(email);
    if (result.success) {
      setValidationStep(2);
    }
  };

  const handleValidationSubmit = async (
    momentoRequisicao: string,
    codigoValidacao: string,
    email: string,
  ) => {
    const result = await validaEmailValidacao(
      momentoRequisicao,
      codigoValidacao,
      email,
    );

    if (result.success) {
      const locationData = JSON.parse(
        sessionStorage.getItem('locationData') || '{}',
      );
      const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
      const companyData = JSON.parse(
        sessionStorage.getItem('companyData') || '{}',
      );

      const emailData = sessionStorage.getItem('emailData') || '';

      const responseSignup = await cadastraRevendedorValidado({
        ...locationData,
        ...userData,
        ...companyData,
        emailData,
      });

      if (responseSignup.success) {
        toast.success('Código autorizado e e-mail validado com sucesso.');
        navigate('/cadastro/concluido');
      } else {
        toast.error(responseSignup.message);
        navigate('/');
      }
    } else {
      toast.error('Código inválido, verifique se preencheu corretamente.');
    }
  };

  return {
    loading,
    error,
    emailStatus,
    validationStep,
    email,
    validationCode,
    handleEmailSubmit,
    handleValidationSubmit,
    setEmail,
    setValidationCode,
  };
};

export default useValidations;
