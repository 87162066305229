import React, { useState, useEffect } from 'react';
import { InputContainer, PrimaryInput } from '../../../../../styles/lib/input';
import { IconCheck } from '../../../../../styles/lib/icons';
import { applyPhoneMask } from '../../../../../utils/functions';
import { phoneRegex } from '../../../../../utils/regex/regex';
import styled from 'styled-components';

export const DealerUserStep: React.FC = () => {
  const MAX_NAME_LENGTH = 30;
  const MAX_SURNAME_LENGTH = 50;

  const [userData, setUserData] = useState({
    nome: '',
    sobrenome: '',
    telefone: '',
  });

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  useEffect(() => {
    const { nome, sobrenome, telefone } = userData;
    setIsValid(!!(nome && sobrenome && phoneRegex.test(telefone)));
  }, [userData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    let formattedValue = value;

    if (id === 'telefone' && value) {
      formattedValue = applyPhoneMask(value);
    } else if ((id === 'nome' || id === 'sobrenome') && value) {
      formattedValue = value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\s]/g, '');
    }

    setUserData(prevState => ({
      ...prevState,
      [id]: formattedValue,
    }));
  };

  return (
    <FormContainer>
      <FormField flex="35%">
        <label htmlFor="nome">Nome</label>
        <InputContainer>
          <PrimaryInput
            id="nome"
            type="text"
            placeholder="Primeiro nome"
            maxLength={MAX_NAME_LENGTH}
            value={userData.nome}
            onChange={handleInputChange}
            className={`rounded ${userData.nome ? 'check' : ''}`}
          />
          {isValid && <IconCheck />}
        </InputContainer>
      </FormField>
      <FormField flex="35%">
        <label htmlFor="sobrenome">Sobrenome</label>
        <InputContainer>
          <PrimaryInput
            id="sobrenome"
            type="text"
            placeholder="Sobrenome"
            maxLength={MAX_SURNAME_LENGTH}
            value={userData.sobrenome}
            onChange={handleInputChange}
            className={`rounded ${userData.sobrenome ? 'check' : ''}`}
          />
          {isValid && <IconCheck />}
        </InputContainer>
      </FormField>
      <FormField flex="30%">
        <label htmlFor="telefone">Telefone de contato</label>
        <InputContainer>
          <PrimaryInput
            id="telefone"
            type="text"
            placeholder="(00) 0 0000-0000"
            value={userData.telefone}
            onChange={handleInputChange}
            className={`rounded ${userData.telefone ? 'check' : ''}`}
          />
          {isValid && <IconCheck />}
        </InputContainer>
      </FormField>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const FormField = styled.div`
  flex: 100%;

  @media (min-width: 768px) {
    flex: ${(props: { flex: string }) => props.flex || '1'};
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
  }
`;
