import styled from 'styled-components';

export const TextBold = styled.span`
  color: #231f20;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0 0;

  @media (max-width: 768px) {
    margin: 30px 0 0;
  }
`;

export const Input = styled.input`
  background-color: #f2f2f2;
  color: #7c7e80;
  padding: 22px 17px;
  width: 304px;
  height: 48px;
  border-radius: 80px;
  font-size: 12px;
  border: 0;
  margin: 8px 0px;
`;

export const Button = styled.button`
  background-color: #f7941d;
  width: 304px;
  height: 48px;
  border-radius: 80px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  margin: 24px 0px;
  cursor: pointer;

  &.loading {
    opacity: 0.8;
    pointer-events: none;
  }
`;

export const LinkWrapper = styled.div`
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const Link = styled.a`
  color: #033b71;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-decoration: underline;
`;

export const LinkBold = styled.a`
  color: #f7941d;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
`;

export const ErrorText = styled.span`
  text-align: left;
  color: red;
  font-size: 12px;
`;

export const PasswordWraper = styled.div`
  position: relative;
`;

export const PasswordControl = styled.a`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
`;

export const AccessWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const RadioButtonWrapper = styled.div`
  color: #231f20;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  font-size: 16px;

  .form-control {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  span {
    &.bolder {
      font-weight: 700;
    }
  }

  input[type='radio'] {
    /* ...existing styles */
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 16px;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Select = styled.select`
  background-color: #f2f2f2;
  color: #7c7e80;
  padding: 0px 16px;
  width: 304px;
  height: 48px;
  border-radius: 80px;
  font-size: 12px;
  border: 0;
  margin: 8px 0px;
`;

export const Option = styled.option``;

export const Title = styled.h1`
  color: #231f20;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 24px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 16px;
  }
`;
