import React, { useState, useEffect } from 'react';
import { InputContainer, PrimaryInput } from '../../../../../styles/lib/input';
import { IconCheck } from '../../../../../styles/lib/icons';
import { applyCepMask } from '../../../../../utils/functions';
import styled from 'styled-components';

export const DealerLocationStep: React.FC = () => {
  const [locationData, setLocationData] = useState({
    cep: '',
    bairro: '',
    logradouro: '',
    municipio: '',
    estado: '',
    numero: '',
    complemento: '',
  });

  const [isValid, setIsValid] = useState(false);
  const MAX_COMPLEMENTO_LENGTH = 100;

  useEffect(() => {
    const storedData = sessionStorage.getItem('locationData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setLocationData({
        ...parsedData,
        cep: applyCepMask(parsedData.cep || ''),
      });
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('locationData', JSON.stringify(locationData));
  }, [locationData]);

  useEffect(() => {
    const { cep, bairro, logradouro, municipio, estado } = locationData;
    setIsValid(!!(cep && bairro && logradouro && municipio && estado));
  }, [locationData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLocationData(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Form>
      <Row>
        <FieldContainer flex="20%">
          <label htmlFor="cep">CEP</label>
          <InputContainer>
            <PrimaryInput
              id="cep"
              type="text"
              placeholder="00000-000"
              value={locationData.cep}
              onChange={handleInputChange}
              className={`rounded ${locationData.cep ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
        <FieldContainer flex="30%">
          <label htmlFor="bairro">Bairro</label>
          <InputContainer>
            <PrimaryInput
              id="bairro"
              type="text"
              value={locationData.bairro}
              onChange={handleInputChange}
              className={`rounded ${locationData.bairro ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
        <FieldContainer flex="30%">
          <label htmlFor="municipio">Município</label>
          <InputContainer>
            <PrimaryInput
              id="municipio"
              type="text"
              value={locationData.municipio}
              onChange={handleInputChange}
              className={`rounded ${locationData.municipio ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
        <FieldContainer flex="15%">
          <label htmlFor="estado">Estado</label>
          <InputContainer>
            <PrimaryInput
              id="estado"
              type="text"
              value={locationData.estado}
              onChange={handleInputChange}
              className={`rounded ${locationData.estado ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
      </Row>
      <Row>
        <FieldContainer flex="30%">
          <label htmlFor="logradouro">Rua</label>
          <InputContainer>
            <PrimaryInput
              id="logradouro"
              type="text"
              value={locationData.logradouro}
              onChange={handleInputChange}
              className={`rounded ${locationData.logradouro ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
        <FieldContainer flex="25%">
          <label htmlFor="numero">Número</label>
          <InputContainer>
            <PrimaryInput
              id="numero"
              type="number"
              value={locationData.numero}
              onChange={handleInputChange}
              className={`rounded ${locationData.numero ? 'check' : ''}`}
              disabled={true}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
        <FieldContainer flex="45%">
          <label htmlFor="complemento">
            Complemento{' '}
            <small>
              <i> - Opcional</i>
            </small>
          </label>
          <InputContainer>
            <PrimaryInput
              id="complemento"
              type="text"
              value={locationData.complemento}
              onChange={handleInputChange}
              className={`rounded ${locationData.complemento ? 'check' : ''}`}
              maxLength={MAX_COMPLEMENTO_LENGTH}
            />
            {isValid && <IconCheck />}
          </InputContainer>
        </FieldContainer>
      </Row>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  flex: ${(props: { flex: string }) => props.flex || '1'};
`;
