import React, { useState } from 'react';
import ArrowDown from '../../assets/arrow-down-order.png';
import * as S from './UsersCardStyles';
import { UserCardProps } from '../../interfaces';
import { SlArrowRight } from 'react-icons/sl';

type CNPJType = string[];

export const UsersCard: React.FC<UserCardProps> = ({
  user,
  children,
  isAccordion = true,
  action,
}) => {
  const [isActive, setIsActive] = useState(false);

  const maxCollapsed = 1;

  const formatCNPJ = (cnpj?: string): string => {
    if (!cnpj) {
      return '-';
    }

    return cnpj?.length > 8
      ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      : cnpj.replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2.$3');
  };

  const renderCNPJs = (cnpjs: CNPJType): JSX.Element => {
    if (isActive) {
      return (
        <>
          {cnpjs.map((elm, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: 'bold' }}>{formatCNPJ(elm)}</span>
              {index < cnpjs.length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return (
        <span style={{ fontWeight: 'bold' }}>
          {formatCNPJ(cnpjs[0])}
          {cnpjs.length > maxCollapsed && '...'}
        </span>
      );
    }
  };

  const handleClick = () => {
    if (isAccordion) {
      setIsActive(!isActive);
    } else if (action) {
      action();
    }
  };

  return (
    <S.Container
      className={isActive ? 'active' : ''}
      style={
        !user.analisado
          ? { background: '#1BC1670D', border: '1px solid #1BC167' }
          : {}
      }
    >
      <S.Top onClick={handleClick}>
        <S.BottomWrapper className={isActive ? 'active' : ''}>
          <S.Cell className="wide">
            <S.Text>{user.nome ?? '-'}</S.Text>
            <S.Text>
              <span>Razão Social</span>
            </S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>{renderCNPJs(user.cnpj)}</S.Text>
            <S.Text>
              <span>CNPJ</span>
            </S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>{user.perfil?.nome}</S.Text>
            <S.Text>
              <span>Ponto de contato</span>
            </S.Text>
          </S.Cell>
          <S.Cell>
            <S.Text>{user.perfil?.nome}</S.Text>
            <S.Text>
              <span>Perfil do usuário</span>
            </S.Text>
          </S.Cell>

          {isAccordion ? (
            <SlArrowRight
              size={18}
              color="#51515C"
              style={{
                rotate: isActive ? '-90deg' : '90deg',
                transition: 'ease 0.4s',
              }}
            />
          ) : (
            <SlArrowRight size={18} color="#51515C" />
          )}
        </S.BottomWrapper>
      </S.Top>
      <S.SendOptions className={isActive ? 'active' : ''}>
        <S.Cell>
          <S.Text>{user.email ?? '-'}</S.Text>
          <S.Text>
            <span>E-mail</span>
          </S.Text>
        </S.Cell>
        <S.RightAlignedContainer>
          <S.Cell>
            <S.Text>
              {user.areasAtuacao.length > 0
                ? user.areasAtuacao.map(area => area).join(', ')
                : '-'}
            </S.Text>
            <S.Text>
              <span>Área de atuação</span>
            </S.Text>
          </S.Cell>
          <S.Cell>
            <div>{children}</div>
            <S.Text>
              <span>Gerenciamento de usuário</span>
            </S.Text>
          </S.Cell>
        </S.RightAlignedContainer>
      </S.SendOptions>
    </S.Container>
  );
};
