import React, { useState } from 'react';
import * as S from './styles';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { EyeIcon, EyeOff } from 'lucide-react';

interface IUserData {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserData>();

  const { isLoading, signIn, getUserData } = useAuth();

  const onSubmit: SubmitHandler<IUserData> = async user => {
    const values = {
      email: user.email,
      password: user.password,
    };
    setError('');
    const response = await signIn(values);

    if (response.status === 500) {
      setError(response.data.error);
    }
  };

  return (
    <S.Container>
      <S.Image src={AlliedLogo} />
      <S.Text> Para acessar o Meu Painel, entre com e-mail e senha </S.Text>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Input
          type="text"
          id="email"
          placeholder="E-mail cadastrado"
          {...register('email', {
            required: true,
          })}
        />
        {errors.email && errors.email.type === 'required' && (
          <S.ErrorText className="required-message">
            E-mail obrigatório
          </S.ErrorText>
        )}
        <S.PasswordWraper>
          <S.Input
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Senha"
            {...register('password', {
              required: true,
            })}
          />
          <S.PasswordControl onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <EyeOff color="#7c7e80" size={20} strokeWidth={2} />
            ) : (
              <EyeIcon color="#7c7e80" size={20} strokeWidth={2} />
            )}
          </S.PasswordControl>
        </S.PasswordWraper>
        {errors.password && errors.password.type === 'required' && (
          <S.ErrorText className="required-message">
            Senha obrigatória
          </S.ErrorText>
        )}
        {error && (
          <S.ErrorText className="required-message">{error}</S.ErrorText>
        )}
        <S.LinkWrapper>
          <S.Link href="/esqueci-a-senha">Esqueci minha senha</S.Link>
        </S.LinkWrapper>
        <S.Button className={isLoading ? 'loading' : ''} type="submit">
          {isLoading ? 'Carregando...' : 'Entrar'}
        </S.Button>
        <S.AccessWrapper>
          <S.TextBold> Não tem conta?</S.TextBold>
          <S.LinkBold href="/cadastro/perfis">Solicite seu acesso</S.LinkBold>
        </S.AccessWrapper>
      </S.Form>
    </S.Container>
  );
};

export default LoginForm;
