import React from 'react';
import { useNavigate } from 'react-router-dom';
import AlliedLogo from '../../assets/allied-empresas-logo.png';
import BackArrow from '../../assets/back-arrow.svg';
import Collaborator from '../../assets/illustrations/SouColaboradorAllied.svg';
import Dealer from '../../assets/illustrations/SouRevendedorAllied.svg';

import { Topbar, LoginFooter } from '../../components';

import { Container, Content } from '../../styles/lib/grid';
import { Button, LinkWrapper, TextLink } from '../../styles/lib/buttons';
import { TitleContent, Text } from '../../styles/lib/typography';
import { Card, CardsContainer } from '../../styles/lib/cards';

export const ScreeningSignup: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Topbar />
      <LinkWrapper>
        <img src={BackArrow} />
        <TextLink href="/">Voltar para login</TextLink>
      </LinkWrapper>
      <img src={AlliedLogo} />
      <Content className="fluid">
        <TitleContent className="center">Cadastro Allied</TitleContent>
        <Text className="no-margin center">
          Selecione abaixo o perfil que melhor se enquadra à sua função.
        </Text>

        <CardsContainer>
          <Card>
            <h3>Sou Colaborador Allied</h3>
            <p className="center">
              Sou colaborador da Allied e preciso criar meu acesso ao sistema.
            </p>
            <img className="card-img" src={Collaborator} alt="Colaborador" />
            <Button
              className="outline"
              onClick={() => navigate('/cadastro/colaborador')}
            >
              Continuar para cadastro
            </Button>
          </Card>
          <Card>
            <h3>Sou Revendedor Allied</h3>
            <p className="center">
              Sou revendedor e desejo cadastrar minha empresa para acesso ao
              portal.
            </p>
            <img className="card-img" src={Dealer} alt="Revendedor" />
            <Button
              className="outline"
              onClick={() => navigate('/cadastro/revendedor')}
            >
              Continuar para cadastro
            </Button>
          </Card>
        </CardsContainer>
      </Content>
      <LoginFooter />
    </Container>
  );
};
