import React from 'react';
import AlliedLogo from '../../../assets/allied-empresas-logo.png';
import BackArrow from '../../../assets/back-arrow.svg';

import { Topbar, LoginFooter } from '../../../components';
import { Container, Content } from '../../../styles/lib/grid';
import { LinkWrapper, TextLink } from '../../../styles/lib/buttons';
import { TitleContent, Text } from '../../../styles/lib/typography';
import { steps } from './Components/DealerStepsData';
import { DealerStepsForms } from './Components/DealerStepsForms';

export const DealerSignup: React.FC = () => {
  return (
    <Container>
      <Topbar />
      <LinkWrapper>
        <img src={BackArrow} />
        <TextLink href="/">Voltar para login</TextLink>
      </LinkWrapper>
      <img src={AlliedLogo} />
      <Content>
        <TitleContent className="center">
          Cadastro de Revendedores Allied
        </TitleContent>
        <Text className="center padding">
          <strong>Bem-vindo à Allied!</strong>
          <p>
            Cadastre sua revenda e aproveite serviços como acompanhamento de
            pedidos, emissão de segunda via de notas e boletos, consulta de
            comissionamento e mais.
          </p>
          <i> A liberação de acesso ocorre em até 24 horas úteis.</i>
        </Text>
        <DealerStepsForms steps={steps} />
      </Content>
    </Container>
  );
};
