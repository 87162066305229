import React, { useState, useRef, useEffect } from 'react';
import { PrimaryInput } from '../../../../../../styles/lib/input';
import { Link } from '../../../../../../styles/lib/buttons';
import useValidations from '../../../../../../hooks/useValidations/useValidations';
import { codeEmailValidation } from '../../../../../../utils/regex/regex';
import { toast } from 'react-toastify';

interface DealerValidationCodeStepProps {
  email: string;
  onResetEmailStep: () => void;
}

const DealerValidationCodeStep: React.FC<DealerValidationCodeStepProps> = ({
  email,
  onResetEmailStep,
}) => {
  const [code, setCode] = useState(['', '', '', '', '']);
  const [timeLeft, setTimeLeft] = useState(300);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const { handleValidationSubmit, handleEmailSubmit, loading } =
    useValidations();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCode(['', '', '', '', '']);
      setIsDisabled(true);
    }
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(
      2,
      '0',
    )}`;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value.toUpperCase();

    if (codeEmailValidation.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (value && index < inputsRef.current.length - 1) {
        inputsRef.current[index + 1].focus();
      }

      if (
        newCode.every(digit => digit !== '') &&
        index === newCode.length - 1
      ) {
        const validationCode = newCode.join('');
        const momentoRequisicao =
          sessionStorage.getItem('validationMoment') || '';
        handleValidationSubmit(momentoRequisicao, validationCode, email);
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleResendCode = () => {
    handleEmailSubmit(email);
    toast.success('Código reenviado por e-mail com sucesso.');
    setTimeLeft(300);
    setIsDisabled(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationCode = code.join('');
    const momentoRequisicao = sessionStorage.getItem('validationMoment') || '';

    if (validationCode.length === 5) {
      await handleValidationSubmit(momentoRequisicao, validationCode, email);
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <div className="spinner" style={{ color: 'white' }}>
            Validando código...
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label>Autenticação de e-mail</label>
        <div style={{ display: 'flex', gap: '10px' }}>
          {code.map((digit, index) => (
            <PrimaryInput
              key={index}
              type="text"
              value={digit}
              onChange={e => handleInputChange(e, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              ref={el => (inputsRef.current[index] = el!)}
              maxLength={1}
              disabled={isDisabled}
              style={{
                width: '60px',
                height: '60px',
                textAlign: 'center',
                fontSize: '1.5rem',
              }}
            />
          ))}
        </div>
        <p
          style={{
            fontWeight: '500',
            marginTop: '10px',
            fontSize: '14px',
            color: '#51515C',
          }}
        >
          Lembramos que o código possui validade de:{' '}
          <span
            style={{
              color: timeLeft === 0 ? 'red' : 'black',
            }}
          >
            {formatTime(timeLeft)}
          </span>
        </p>
        <div style={{ display: 'flex', marginTop: '15%' }}>
          <p>
            {timeLeft === 0 ? 'O tempo acabou ' : 'Não recebeu o seu código? '}
            <Link type="button" onClick={handleResendCode}>
              {timeLeft === 0
                ? 'Reenviar código por e-mail'
                : 'Reenviar código'}
            </Link>
          </p>
        </div>
        <p>
          Caso queira alterar o e-mail,{' '}
          <Link
            className="primary"
            onClick={() => {
              if (onResetEmailStep) {
                onResetEmailStep();
              }
            }}
          >
            clique aqui.
          </Link>
        </p>
      </form>
    </div>
  );
};

export default DealerValidationCodeStep;
