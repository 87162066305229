import React, { useState } from 'react';

import * as S from './CollaboratorSignup.styles';
import AlliedLogo from '../../../assets/allied-empresas-logo.png';
import BackArrow from '../../../assets/back-arrow.svg';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAccess } from '../../../hooks';
import { LoginFooter, Topbar } from '../../../components';
import { LinkWrapper, TextLink } from '../../../styles/lib/buttons';
import { Text, TitleContent } from '../../../styles/lib/typography';
import { Container, Content } from '../../../styles/lib/grid';

interface IUserData {
  email: string;
  nome?: string;
  cnpj?: string;
  areaAtuacao?: string;
}

export const CollaboratorSignup: React.FC = () => {
  const { registerUser, isLoadingAPICall, error } = useAccess();
  const [isSent, setIsSent] = useState(false);

  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserData>();

  const onSubmitColab: SubmitHandler<IUserData> = async user => {
    const values = {
      email: user.email,
      nome: user.email.split('@')[0],
      cnpj: '',
      areaAtuacao: '',
    };

    await registerUser(values).then(data => {
      if (data.status === 200) {
        setIsSent(true);
      }
    });
  };

  return (
    <Container>
      <Topbar />
      <LinkWrapper>
        <img src={BackArrow} />
        <TextLink href="/">Voltar para login</TextLink>
      </LinkWrapper>

      <img src={AlliedLogo} />

      <Content className="fluid small">
        <TitleContent className="center">
          Cadastro de Colaboradores Allied
        </TitleContent>
        {!isSent && (
          <>
            <Text className="center">
              Vamos começar a solicitação do seu acesso de{' '}
              <span className="bold"> Colaborador Allied. </span>
              Para solicitar o seu primeiro acesso, preencha as informações
              abaixo. Caso seu cadastro seja aprovado, você receberá a sua senha
              no e-mail informado.
            </Text>

            <S.Form onSubmit={handleSubmit(onSubmitColab)}>
              <S.Input
                type="text"
                id="email"
                placeholder="E-mail"
                {...register('email', {
                  required: true,
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <S.ErrorText className="required-message">
                  E-mail obrigatório
                </S.ErrorText>
              )}
              {error && (
                <S.ErrorText className="required-message">{error}</S.ErrorText>
              )}
              <S.Button
                className={isLoadingAPICall ? 'loading' : ''}
                type="submit"
              >
                {isLoadingAPICall ? 'Carregando...' : 'Cadastrar'}
              </S.Button>
            </S.Form>
          </>
        )}

        {isSent && (
          <>
            <S.Title>Solicitação realizada com sucesso! </S.Title>
            <Text className="final">
              Verifique a caixa de entrada no seu e-mail informado com o status
              da sua solicitação.
            </Text>
          </>
        )}
      </Content>
      <LoginFooter />
    </Container>
  );
};
