import { phoneRegex } from '../../../../utils/regex/regex';

export interface StepValidationRules {
  [step: number]: () => boolean;
}

export const StepValidationService = {
  validateStep: (step: number, rules: StepValidationRules): boolean => {
    const validate = rules[step];
    return validate ? validate() : true;
  },
};

export const validationRules: StepValidationRules = {
  0: () => {
    const companyData = JSON.parse(
      sessionStorage.getItem('companyData') || '{}',
    );
    return (
      !!companyData.cnpj &&
      !!companyData.stateRegistration &&
      !!companyData.corporateName &&
      !!companyData.tradeName
    );
  },
  1: () => {
    const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
    return (
      !!userData.nome &&
      !!userData.sobrenome &&
      !!phoneRegex.test(userData.telefone)
    );
  },
  2: () => {
    const locationData = JSON.parse(
      sessionStorage.getItem('locationData') || '{}',
    );
    return (
      !!locationData.cep &&
      !!locationData.bairro &&
      !!locationData.logradouro &&
      !!locationData.municipio &&
      !!locationData.estado &&
      !!locationData.numero
    );
  },
};
