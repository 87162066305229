import styled from 'styled-components';

export const TitleContent = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  color: rgb(3, 59, 113);

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  &.center {
    text-align: center;
  }
`;

export const Text = styled.p`
  width: 100%;
  color: #231f20;
  font-weight: 400;
  margin: 0 0 32px;
  font-size: 15px;

  &.no-margin {
    margin: 0;
  }

  &.padding {
    padding: 0 25%;
    @media (max-width: 1280px) {
      padding: 0 10%;
    }
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .hide-lg {
    display: block;
  }

  .hide-sm {
    display: none;
  }

  .bold {
    font-weight: 700;
  }

  &.center {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: start;
    display: inline-block;
    margin: 0 0 16px;
    font-size: 14px;

    .hide-lg {
      display: none;
    }

    .hide-sm {
      display: block;
    }

    &.final {
      text-align: center;
    }
  }
`;

export const HintError = styled.p`
   {
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
    &.small {
      margin-left: 5px;
      color: #ff0000;
      font-size: 12px;
    }
  }
`;

export const TitleDialog = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
  color: rgb(3, 59, 113);

  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
