import styled from 'styled-components';

export const ComboboxWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Combobox = styled.select`
  background-color: #fff;
  border: 1px solid #033b71;
  color: black;
  padding: 0 30px 0 30px;
  width: 100%;
  height: 35px;
  border-radius: 20px;
  font-size: 12px;
  margin: 8px 0;
  appearance: none;

  option {
    background-color: #ffffff;
    font-size: 12px;
    color: #333;
  }

  option:disabled {
    background-color: #f2f2f2;
    color: #b0b0b0;
  }

  &::-ms-expand,
  &::-webkit-appearance {
    display: none;
  }
`;

const iconStyle = `
  position: absolute;
  top: 67%;
  transform: translateY(-50%);
  font-size: 13px;
  color: #033b71;
`;

export const LeftIcon = styled.span`
  ${iconStyle};
  left: 10px;
`;

export const RightIcon = styled.span`
  ${iconStyle};
  right: 13px;
`;
