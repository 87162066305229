import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as S from '../../UsersStyles';
import { usePermissions } from '../../../../../hooks/usePermissions';
import makeAnimated from 'react-select/animated';
import { WithContext as ReactTags } from 'react-tag-input';

import { useClient } from '../../../../../hooks/useClient';
import { useAccess } from '../../../../../hooks';
import { sendNotification } from '../../../../../utils/functions';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaRegCircleQuestion } from 'react-icons/fa6';
import { LoadingCircle } from '../../../../../components';

const toastConfig = {
  autoClose: 3000,
  hideProgressBar: true,
  type: 'success',
};

interface Tag {
  id: string;
  text: string;
}

export const RegistrationClientForm: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const animatedSelectComponents = makeAnimated();
  const [formData, setFormData] = useState<any>({
    grupoEconomico: [],
    areasAtuacao: [],
    emails: [],
    cnpjRaiz: [],
  });

  const isFormValid = () => {
    const requiredFields = ['cnpjRaiz', 'emails', 'grupoEconomico'];
    return requiredFields.every(field => formData[field].length > 0);
  };

  const handleCancel = () => {
    setCtrlError(false);
    onCancel();
  };

  const [sendData, setSendData] = useState<any>(false);
  const [{ reject, isLoading }, consultingCnpj] = useClient();
  const { isLoadingAPICall, error, registerFinalClient, handleAPIError } =
    useAccess();
  const [{ areasActing }, , , , , fetchAreasActing] = usePermissions();

  const [ctrlError, setCtrlError] = useState<boolean>(false);

  const handleAdditionCnpj = async (tag: { text: string; id: string }) => {
    const cnpjText: string = tag.text.replace(/[^\d]/g, '');
    const formattedCnpj = `${cnpjText.slice(0, 3)}.${cnpjText.slice(
      3,
      6,
    )}.${cnpjText.slice(6, 9)}`;

    const newCnpj = {
      id: tag.id,
      text: formattedCnpj,
    };

    const responseConsulted = await consultingCnpj(cnpjText);
    const grupoEconomico = responseConsulted?.cliente?.grupoEconomico || '';

    if (grupoEconomico && !formData.grupoEconomico.includes(grupoEconomico)) {
      setFormData({
        ...formData,
        cnpjRaiz: [...formData.cnpjRaiz, newCnpj],
        grupoEconomico: [...formData.grupoEconomico, grupoEconomico],
      });
    }
  };

  const handleDeleteCnpj = (index: number) => {
    const newCnpjList = [...formData.cnpjRaiz];
    const newGrupoEconomicoList = [...formData.grupoEconomico];

    newCnpjList.splice(index, 1);

    if (index < newGrupoEconomicoList.length) {
      newGrupoEconomicoList.splice(index, 1);
    }

    setFormData({
      ...formData,
      cnpjRaiz: newCnpjList,
      grupoEconomico: newGrupoEconomicoList,
    });
  };

  const handleAreaDeAtuacaoChange = (areas: any) => {
    setFormData({ ...formData, areasAtuacao: areas });
  };

  const handleAdditionEmail = (email: any) => {
    setFormData({
      ...formData,
      emails: [...formData.emails, email],
    });
  };

  const handleDragEmail = (email: any, currPos: any, newPos: any) => {
    const newEmails = formData.emails.slice();

    newEmails.splice(currPos, 1);
    newEmails.splice(newPos, 0, email);

    setFormData({
      ...formData,
      emails: newEmails,
    });
  };

  const handleCnpjsDrag = (
    tag: { id: string; text: string },
    currPos: number,
    newPos: number,
  ) => {
    const newCNPJS = [...formData.cnpjRaiz];
    const [movedCNPJ] = newCNPJS.splice(currPos, 1);
    newCNPJS.splice(newPos, 0, movedCNPJ);

    const newgrupoEconomico = [...formData.grupoEconomico];

    const currentGrupo = newgrupoEconomico[currPos];

    newgrupoEconomico.splice(currPos, 1);
    newgrupoEconomico.splice(newPos, 0, currentGrupo);

    setFormData({
      ...formData,
      cnpjRaiz: newCNPJS,
      grupoEconomico: newgrupoEconomico,
    });
  };
  const handleDeleteEmail = (i: any) => {
    setFormData({
      ...formData,
      emails: formData.emails.filter((email: any, index: any) => index !== i),
    });
  };

  const handleRegisterForm = async () => {
    const information = {
      ...formData,
      cnpjRaiz: formData.cnpjRaiz.map((cnpj: any) =>
        cnpj.text.replace(/[.\-/]/g, ''),
      ),
      emails: formData.emails.map((email: Partial<any>) => email.id),
    };

    const success: boolean = await registerFinalClient(information);

    if (success) {
      sendNotification('Cliente cadastrado com sucesso.', toastConfig);
      handleCancel();
    } else {
      setCtrlError(true);
    }
  };

  useEffect(() => {
    fetchAreasActing();
  }, []);

  const renderGrupoEconomico = () => {
    const { grupoEconomico } = formData;

    if (grupoEconomico.length === 0) {
      return '-';
    }

    const primary = grupoEconomico[0];
    const others = grupoEconomico.slice(1);

    return (
      <S.ListInline>
        <ul>
          <li>
            <span>Principal </span> {primary}
          </li>
          {others.length > 0 && (
            <li className="same">
              <span>Outros </span> {others.join(', ')}
            </li>
          )}
        </ul>
      </S.ListInline>
    );
  };

  return (
    <S.ClientRegistrationForm>
      {sendData === false ? (
        <>
          <S.Text>
            Preencha as informações abaixo para realizar o cadastro.
          </S.Text>

          <S.FormControl style={{ paddingBottom: 0 }}>
            <S.Text className="bold no-margin small">CNPJ Raiz</S.Text>

            <ReactTags
              classNames={{ tags: 'ReactTags__tags secondary' }}
              tags={formData.cnpjRaiz}
              handleAddition={handleAdditionCnpj}
              handleDelete={handleDeleteCnpj}
              handleDrag={handleCnpjsDrag}
              delimiters={[188, 13]}
              placeholder="Digite aqui os cnpjs..."
            />
          </S.FormControl>

          {reject && <S.Text className="small-danger">{reject}</S.Text>}

          <>
            <S.FormControl style={{ paddingTop: 10 }}>
              <S.Text className="bold no-margin small flexing">
                Grupos econômicos{' '}
                <FaRegCircleQuestion
                  data-tooltip-id="info-tooltip"
                  data-tooltip-content="O grupo econômico principal é determinado a partir do primeiro CNPJ raiz informado."
                  style={{
                    width: '1.1em',
                    height: '1.1em',
                    marginLeft: '5px',
                    color: 'gray',
                    cursor: 'pointer',
                  }}
                />
              </S.Text>
              <ReactTooltip id="info-tooltip" place="top" />
              <div style={{ paddingTop: 5 }}>{renderGrupoEconomico()} </div>
            </S.FormControl>

            <S.FormControl>
              <S.Text className="bold no-margin small">
                E-mail(s) para acesso
              </S.Text>
              <ReactTags
                classNames={{ tags: 'ReactTags__tags secondary' }}
                tags={formData.emails}
                inline={false}
                handleDelete={handleDeleteEmail}
                handleAddition={handleAdditionEmail}
                handleDrag={handleDragEmail}
                delimiters={delimiters}
                placeholder="Digite aqui os emails..."
                autofocus={false}
                inputFieldPosition="inline"
                autocomplete
              />
            </S.FormControl>

            <S.FormControl>
              <S.Text className="bold no-margin small">
                Área(s) de atuação (opcional)
              </S.Text>
              <S.CustomMultiSelect
                classNamePrefix={'Select'}
                className="secondary"
                components={animatedSelectComponents}
                isMulti
                options={areasActing}
                value={formData.areasAtuacao}
                onChange={handleAreaDeAtuacaoChange}
                placeholder="Selecione área(s) de atuação"
                isClearable
              />
            </S.FormControl>

            <S.Buttons>
              <S.IconButton
                type="submit"
                className="text"
                onClick={handleCancel}
              >
                Cancelar
              </S.IconButton>
              <S.IconButton
                type="button"
                className={`success ${isFormValid() ? '' : 'disabled'}`}
                onClick={() => {
                  if (isFormValid()) {
                    setSendData(formData);
                    setCtrlError(false);
                  }
                }}
                disabled={!isFormValid()}
              >
                Cadastrar
              </S.IconButton>
            </S.Buttons>
          </>
        </>
      ) : (
        <>
          <S.Text>
            Você está finalizando o cadastro. Confira se as informações foram
            preenchidas corretamente e se tudo estiver correto, prossiga.
          </S.Text>
          <S.Featured className="background margin-bottom">
            {[
              {
                label: 'CNPJ Raiz',
                value: formData?.cnpjRaiz
                  .map((cnpj: Tag) => cnpj.text ?? cnpj)
                  .join(', '),
              },
              {
                label: 'Grupo econômico',
                value: renderGrupoEconomico(),
              },
              {
                label: 'E-mails para acesso',
                value: formData?.emails
                  .map((email: Tag) => email.text ?? email)
                  .join(', '),
              },
              {
                label: 'Área(s) de atuação',
                value: formData?.areasAtuacao?.length
                  ? formData?.areasAtuacao
                      .map((area: any) => area.label ?? area)
                      .join(', ')
                  : '-',
              },
            ].map((item, index) => (
              <React.Fragment key={index}>
                {item.value !== undefined && (
                  <>
                    <S.Text className="bold top-margin">{item.label}</S.Text>
                    <S.Text>{item.value ?? '-'}</S.Text>
                  </>
                )}
              </React.Fragment>
            ))}
          </S.Featured>
          {error && ctrlError === true && (
            <S.Text className="small-danger top-margin">{error}</S.Text>
          )}
          <S.Buttons>
            <S.IconButton
              type="submit"
              className="text"
              onClick={() => {
                setSendData(false);
              }}
            >
              Voltar
            </S.IconButton>
            <S.IconButton
              type="button"
              className="success"
              onClick={handleRegisterForm}
            >
              {isLoadingAPICall ? <LoadingCircle /> : 'Confirmar'}
            </S.IconButton>
          </S.Buttons>
        </>
      )}
    </S.ClientRegistrationForm>
  );
};
