import styled, { css } from 'styled-components';
import * as c from '../palette';

const baseInputStyles = css`
  color: ${c.textGray};
  padding: 20px 15px;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 12px;
  margin: 8px 0px;
  &:focus {
    border: 1px solid rgb(105, 105, 105);
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const PrimaryInput = styled.input`
  background-color: #f2f2f2;
  border: 1px solid #dadada;
  color: #696969;
  padding: 20px 15px;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 12px;
  margin: 8px 0px 0px;

  &:focus {
    border: 1px solid #222;
  }

  &:disabled {
    background-color: #f2f2f2;
    color: #696969;
    cursor: not-allowed;
  }

  &.check {
    background-color: #e8f0fe;
    color: #2c2d2f;
  }

  &.rounded {
    border-radius: 25px !important;
  }
`;
