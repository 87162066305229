import styled from 'styled-components';

import checkIcon from '../../assets/icons/iconcheck.svg';

export const IconCheck = styled.span`
  position: absolute;
  top: 62%;
  right: 15px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-image: url(${checkIcon});
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
`;
