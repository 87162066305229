import styled from 'styled-components';
import * as Buttons from '../../../styles/lib/buttons';
import * as Grid from '../../../styles/lib/grid';
import * as Input from '../../../styles/lib/input';

export { Buttons, Grid, Input };

export const AddButton = styled(Buttons.Button)`
  min-height: 40px;
  img {
    margin-right: 5px;
  }
  @media (max-width: 768px) {
    margin-top: 3px;
  }
`;

export const RowContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;

  & > * {
    flex: 1;
  }

  & > :first-child {
    flex: 4;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Text = styled.div`
  width: 100%;
  margin: 0;

  &.extended {
    width: 100%;
    max-width: 350px;
    color: #033b71;
  }

  &.bold {
    font-weight: 700;
  }

  &.top-margin {
    margin: 16px 0 8px;
  }

  &.inline {
    display: inline;
  }

  &.small-danger {
    font-size: 12px;
    color: red;
    margin-top: 1%;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &.small {
    font-size: 13px;
  }
`;

export const FormPermission = styled.form`
  .form-control {
    padding: 1% 0 5% 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10%;
    label {
      width: 100%;
      font-size: 14px;
      display: flex;
      align-items: center;

      input {
        margin-right: 1%;
      }
    }
  }
`;
