import React from 'react';
import { DealerCompanyStep } from './Steps/DealerCompanyStep';
import { DealerUserStep } from './Steps/DealerUserStep';
import { DealerLocationStep } from './Steps/DealerLocationStep';
import { DealerEmailStep } from './Steps/Email/DealerEmailStep';

export const steps = [
  {
    title: 'Dados da empresa',
    description:
      'Informe o CNPJ para que possamos consultar automaticamente as informações cadastrais da sua empresa na base de dados da Receita Federal.',
    form: <DealerCompanyStep />,
  },
  {
    title: 'Informações do Responsável',
    description: 'Preencha os dados do responsável pela empresa.',
    form: <DealerUserStep />,
  },
  {
    title: 'Endereço',
    description:
      'Os campos abaixo foram preenchidos automaticamente com informações da Receita Federal.',
    form: <DealerLocationStep />,
  },
  {
    title: 'Informações para Login',
    description:
      'Nós entraremos em contato através deste e-mail. Ele também servirá para acesso á plataforma.',
    form: <DealerEmailStep />,
  },
];
