import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  width: 280px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Progress = styled.div<{ progress: number }>`
  height: 100%;
  width: ${props => props.progress}%;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
`;
