import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const Content = styled.div`
  padding: 40px 20px;
  width: 100%;
  max-width: 1280px;
  min-height: auto;

  &.padding-off {
    padding: 0;
  }

  &.small {
    max-width: 680px;
    @media (max-width: 600px) {
      max-width: auto;
    }
  }

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }

  &.modify-content {
    padding: 40px 20px 0px 20px !important;
    min-height: 0px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin: 24px auto;
  display: none;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    display: block;
    p {
      text-align: center;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 3%;

  &.between {
    width: 100%;
    justify-content: space-between;
    ul:nth-child(1) {
      width: 50%;
    }
    ul:nth-child(2) {
      width: 50%;
    }
  }
`;
