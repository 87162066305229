import React, { useState, useEffect } from 'react';
import {
  InputContainer,
  PrimaryInput,
} from '../../../../../../styles/lib/input';
import { IconCheck } from '../../../../../../styles/lib/icons';
import {
  Combobox,
  ComboboxWrapper,
  LeftIcon,
  RightIcon,
} from '../../../../../../styles/lib/combobox';
import { FaChevronDown, FaEnvelope } from 'react-icons/fa6';
import { Button } from '../../../../../../styles/lib/buttons';
import {
  StyledCheckbox,
  StyledLabel,
} from '../../../../../../styles/lib/checkbox';
import DealerValidationCodeStep from './DealerValidationCodeStep';
import useValidations from '../../../../../../hooks/useValidations/useValidations';
import {
  corporateEmailRegex,
  domainValidationRegex,
  emailRegex,
} from '../../../../../../utils/regex/regex';
import styled from 'styled-components';

export const DealerEmailStep: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isCorporateEmail, setIsCorporateEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [showDomainInfo, setShowDomainInfo] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [title, setTitle] = useState('Informações para Login');
  const [description, setDescription] = useState(
    'Nós entraremos em contato através deste e-mail. Ele também servirá para acesso à plataforma.',
  );

  const [controllerCnpjEmail, setControllerCnpjEmail] = useState(true);

  const { handleEmailSubmit } = useValidations();

  useEffect(() => {
    const savedEmail = sessionStorage.getItem('emailData');
    if (savedEmail) {
      setEmail(savedEmail);
      setControllerCnpjEmail(true);
    }
  }, []);

  useEffect(() => {
    if (email) {
      sessionStorage.setItem('emailData', email);
    }
  }, [email]);

  useEffect(() => {
    const isEmailValid = emailRegex.test(email);
    const isCorporate =
      corporateEmailRegex.test(email) && !domainValidationRegex.test(email);

    setIsValid(isEmailValid);

    if (controllerCnpjEmail) {
      setIsCorporateEmail(true);
    } else {
      setIsCorporateEmail(isCorporate);
    }

    if (email && !isEmailValid) {
      setEmailError('E-mail inválido. Verifique o formato.');
      setShowDomainInfo(false);
    } else if (email && !isCorporate) {
      setEmailError(
        'Não aceitamos domínios gratuitos, como Gmail, Yahoo ou similares.',
      );
      setShowDomainInfo(false);
    } else {
      setEmailError('');
      setShowDomainInfo(true);
    }
  }, [email]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleComboboxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'other') {
      setEmail('');
      setIsAgreed(true);
      setIsEditing(true);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(e.target.checked);
  };

  const handleResetEmailStep = () => {
    setIsEmailSent(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isValid && isCorporateEmail && isAgreed) {
      try {
        setTitle('Autenticação de e-mail');
        setDescription(
          `Enviamos um código para o endereço de e-mail informado: ${email} Confira a sua caixa de entrada e spam.`,
        );
        handleEmailSubmit(email);
        setIsEmailSent(true);
      } catch (error) {
        setEmailError('Erro ao enviar o código. Tente novamente.');
      }
    }
  };

  return (
    <FlexContainer>
      <Content>
        <h2>{title}</h2>
        <p className="size16">{description}</p>
      </Content>
      <FormContainer>
        {!isEmailSent ? (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {isValid && !isEditing && (
              <ComboboxWrapper>
                <LeftIcon>
                  <FaEnvelope />
                </LeftIcon>
                <label htmlFor="_email">Email para login</label>
                <Combobox value={email} onChange={handleComboboxChange}>
                  <option value={email}>{email}</option>
                  <option value="other">Escolher outro e-mail</option>
                </Combobox>
                <RightIcon>
                  <FaChevronDown />
                </RightIcon>
              </ComboboxWrapper>
            )}

            {isEditing && (
              <>
                <label htmlFor="email">E-mail</label>
                <InputContainer>
                  <PrimaryInput
                    id="email"
                    type="email"
                    placeholder="nome@empresa.com.br"
                    value={email}
                    onChange={handleInputChange}
                    className={`rounded ${
                      isValid && isCorporateEmail ? 'check' : ''
                    }`}
                  />
                  {isValid && isCorporateEmail && <IconCheck />}
                </InputContainer>

                {showDomainInfo && (
                  <p>
                    <small>
                      Não aceitamos domínios gratuitos, como Gmail, Yahoo ou
                      similares.
                    </small>
                  </p>
                )}

                {emailError && (
                  <p style={{ color: 'red' }}>
                    <small>{emailError}</small>
                  </p>
                )}
              </>
            )}

            {!isEditing && (
              <div style={{ margin: '30px 0 50px', display: 'flex' }}>
                <StyledCheckbox
                  type="checkbox"
                  id="terms"
                  checked={isAgreed}
                  onChange={handleCheckboxChange}
                />
                <StyledLabel htmlFor="terms">
                  Confirmo que este é o meu endereço de e-mail.
                </StyledLabel>
              </div>
            )}

            <Button
              className="auto-width"
              type="submit"
              style={{ marginTop: isEditing ? '80px' : '' }}
              disabled={!isAgreed || !isCorporateEmail || !isValid}
            >
              Validar e-mail
            </Button>
          </form>
        ) : (
          <DealerValidationCodeStep
            email={email}
            onResetEmailStep={handleResetEmailStep}
          />
        )}
      </FormContainer>
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  width: 45%;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const FormContainer = styled.div`
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
