import { useState } from 'react';
import axios, { AxiosError } from 'axios';

interface ErrorResponse {
  detalhes?: string; // Tornar opcional para lidar com casos em que 'detalhes' não existe
}

interface CNPJData {
  fetchCNPJData: (cnpj: string) => Promise<any | null>;
  loading: boolean;
  error: string | null;
  limitError: string | null;
  isLimitReached: boolean;
}

export const useCNPJ = (baseUrl = 'https://publica.cnpj.ws/cnpj'): CNPJData => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [limitError, setLimitError] = useState<string | null>(null);
  const [isLimitReached, setIsLimitReached] = useState(false);

  const fetchCNPJData = async (cnpj: string): Promise<any | null> => {
    setLoading(true);
    setError(null);
    setLimitError(null);
    setIsLimitReached(false);

    try {
      const response = await axios.get(`${baseUrl}/${cnpj}`);
      const { situacao_cadastral } = response.data.estabelecimento;

      if (situacao_cadastral !== 'Ativa') {
        setError('CNPJ com situação cadastral inválida.');
        return null;
      }

      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError<ErrorResponse>;
      const errorData = axiosError.response?.data;

      if (axiosError.response?.status === 429) {
        setLimitError(errorData?.detalhes || 'Limite de requisições atingido.');
        setIsLimitReached(true);
      } else {
        setError(
          errorData?.detalhes || 'Erro desconhecido ao buscar dados do CNPJ.',
        );
      }

      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchCNPJData,
    loading,
    error,
    limitError,
    isLimitReached,
  };
};
