import styled from 'styled-components';

export const StyledCheckbox = styled.input`
  margin-right: 8px;
  accent-color: #033b71;
`;

export const StyledLabel = styled.label`
  font-size: 15px;
  color: #51515c;
  cursor: pointer;
`;
