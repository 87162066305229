import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './PermissionsStyles';
import Add from '../../../assets/buttons/add.svg';
import CollapseList from '../../../components/profiles-collapse';
import { usePermissions } from '../../../hooks/usePermissions';
import { IProfileGroup } from '../../../interfaces';
import {
  Loading,
  LoadingCircle,
  ModalResponsive,
  Pagination,
} from '../../../components';
import { ToastContainer, Slide } from 'react-toastify';
import { RegitrationFormPermissions } from './RegistrationPermissionForm/RegistrationPermissionForm';

export const Permissions: React.FC = () => {
  const [
    {
      profileGroups,
      profilePagination,
      permissionsProfile,
      isLoading,
      modalLoading,
      error,
    },
    fetchProfilesGroups,
    fetchPermissionProfiles,
    insertOrEditPermissions,
    toggleActivatePermission,
  ] = usePermissions();

  const [openManagementModal, setOpenManagementModal] = useState(false);
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false);

  const [modalAction, setModalAction] = useState('');
  const [modalData, setModalData] = useState<IProfileGroup | any>({});

  const handleOpenModal = (action: string, data?: IProfileGroup | any) => {
    setModalData(data);
    setModalAction(action);
    setOpenManagementModal(true);
  };

  const handleOpenDeactivationModal = (dataChild: IProfileGroup) => {
    setModalData(dataChild);
    if (dataChild.qtdUsuarios > 0) {
      setOpenConfirmationModal(true);
    } else {
      setOpenDeactivationModal(true);
    }
  };

  const handleSave = (dataChild: IProfileGroup | any) => {
    insertOrEditPermissions(dataChild, modalAction !== 'criar' ? true : false);
  };

  const handleCancel = () => {
    setOpenManagementModal(false);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    size: 10,
  });

  const totalPages = Math.ceil(profilePagination.totalItems / pagination.size);

  useEffect(() => {
    fetchProfilesGroups(undefined, pagination);
    fetchPermissionProfiles();
  }, []);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [load, setLoading] = useState(false);

  const handleConfirmToggleStatus = async () => {
    try {
      setLoading(true);
      await toggleActivatePermission(modalData.id, !modalData.ativo);
      setOpenDeactivationModal(false);
    } catch (error) {
      console.error(`Erro `, error);
    } finally {
      setLoading(false);
      fetchProfilesGroups(undefined, pagination);
    }
  };

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
      });
      fetchProfilesGroups(undefined, {
        current: index,
        size: pagination.size,
      });
    }
  };

  return (
    <S.Grid.Container>
      <S.Grid.Content>
        <S.RowContent>
          <S.Text>
            Confira os dados, altere ou edite perfis de usuários na lista
            abaixo.
          </S.Text>
          <S.AddButton onClick={() => handleOpenModal('criar', {})}>
            <img src={Add} width={12} />
            Novo perfil de acesso
          </S.AddButton>
        </S.RowContent>
        <div style={{ textAlign: 'center' }}>{isLoading && <Loading />}</div>
        {!isLoading &&
          profileGroups.map((group: IProfileGroup, index: number) => (
            <CollapseList
              key={index}
              id={group.id}
              nome={group.nome}
              qtdUsuarios={group.qtdUsuarios}
              ativo={group.ativo}
              permissionsProfile={permissionsProfile}
              log={{
                dataCriacaoLog: group.dataCriacaoLog,
                usuarioAlteracaoPerfil: group.usuarioAlteracaoPerfil,
              }}
              permissoes={group.permissoes}
              onEditButtonClick={(data: IProfileGroup | any) =>
                handleOpenModal('editar', data)
              }
              onDeactivateButtonClick={(data: IProfileGroup | any) =>
                handleOpenDeactivationModal(data)
              }
            />
          ))}
        <Pagination
          startWithZero={false}
          currentPage={pagination.current}
          paginationSize={pagination.size}
          paginationSearch={paginationSearch}
          totalPages={totalPages}
        />
      </S.Grid.Content>

      {openManagementModal && (
        <ModalResponsive
          title={
            modalAction === 'criar'
              ? 'Criação do perfil de acesso'
              : 'Edição do perfil de acesso'
          }
          isOpenModal={openManagementModal}
          handleOpenModal={() => setOpenManagementModal(false)}
        >
          <RegitrationFormPermissions
            permissions={permissionsProfile}
            profileGroup={modalAction === 'criar' ? false : modalData}
            onSave={handleSave}
            onCancel={handleCancel}
            load={modalLoading || false}
          />
        </ModalResponsive>
      )}

      {openDeactivationModal && (
        <ModalResponsive
          title={`Tem certeza que deseja ${
            modalData.ativo ? 'desativar' : 'reativar'
          } esse perfil?`}
          isOpenModal={openDeactivationModal}
          handleOpenModal={() => setOpenDeactivationModal(false)}
        >
          <S.Text className="small">
            Ao {modalData.ativo ? 'desativar' : 'reativar'} este perfil,{' '}
            {modalData.ativo
              ? 'não ficará mais disponível para nenhum usuário'
              : 'ficará disponível para todos os usuários'}
            .
          </S.Text>
          <S.Buttons.ButtonsContainer className="right">
            <S.Buttons.IconButton
              onClick={() => setOpenDeactivationModal(false)}
            >
              Cancelar
            </S.Buttons.IconButton>
            <S.Buttons.IconButton
              className="danger bold"
              onClick={handleConfirmToggleStatus}
              disabled={load}
            >
              {load ? (
                <LoadingCircle />
              ) : modalData.ativo ? (
                'Desativar'
              ) : (
                'Reativar'
              )}
            </S.Buttons.IconButton>
          </S.Buttons.ButtonsContainer>
        </ModalResponsive>
      )}

      {openConfirmationModal && (
        <ModalResponsive
          title="Ops! Perfil com usuários relacionados."
          isOpenModal={openConfirmationModal}
          handleOpenModal={() => setOpenConfirmationModal(false)}
        >
          <S.Text className="small">
            Para poder desativar este perfil, você deve remover os usuários que
            estão relacionados ao mesmo.
          </S.Text>
          <S.Buttons.ButtonsContainer className="right">
            <S.Buttons.IconButton
              className="update"
              onClick={() => setOpenConfirmationModal(false)}
            >
              Ok
            </S.Buttons.IconButton>
          </S.Buttons.ButtonsContainer>
        </ModalResponsive>
      )}

      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Grid.Container>
  );
};
