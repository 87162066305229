import styled from 'styled-components';
import { filterColors } from '../palette';

export const spacing = {
  small: '4px',
  medium: '8px',
  large: '12px',
  xLarge: '24px',
};

export const sizes = {
  borderRadius: '28px',
  inputHeight: '33px',
  buttonHeight: '50px',
  buttonWidth: '100%',
  iconSize: '12px',
};

export const fonts = {
  size: {
    small: '13px',
    medium: '16px',
  },
  weight: {
    normal: 400,
    bold: 700,
  },
};

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 375px) {
    flex-direction: row;
  }
`;

export const Box = styled.div`
  display: flex;
  width: 270px;
  gap: ${spacing.large};
  position: relative;
`;

export const Search = styled.div`
  margin: ${spacing.xLarge} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.medium};
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.small};
  width: 100%;
  border: 1.5px solid ${filterColors.border};
  border-radius: ${sizes.borderRadius};
  padding: ${spacing.medium};

  :focus-within {
    border: 2px solid ${filterColors.borderBold};
  }

  @media (min-width: 650px) {
    flex-direction: row;
    height: ${sizes.buttonHeight};
  }
`;

export const Select = styled.select`
  padding: ${spacing.medium};
  border: 1px solid ${filterColors.border};
  border-radius: ${sizes.borderRadius};
  background: ${filterColors.background};
  &.highlighted {
    background: transparent;
    min-width: 130px;
  }
`;

export const Option = styled.option``;

export const Input = styled.input`
  width: 100%;
  height: ${sizes.inputHeight};
  padding-left: ${spacing.medium};
  border: none;
  outline: none;
`;

export const InputDate = styled.input`
  color: ${filterColors.secondary};
  border: 1px solid ${filterColors.border};
  border-radius: ${sizes.borderRadius};
  padding: ${spacing.medium};
`;

export const InputDateStart = styled(InputDate)`
  display: flex;
  width: 100%;
`;

export const InputDateEnd = styled(InputDate)`
  display: flex;
  width: 100%;
`;

export const SeparatorHorizontal = styled.div`
  bottom: 22px;
  right: 133px;
  position: absolute;
  width: 5px;
  height: 2px;
  background: ${filterColors.border};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${spacing.large};

  @media (min-width: 1024px) {
    width: 50%;
  }
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${filterColors.primary};
  width: ${sizes.buttonWidth};
  height: ${sizes.buttonHeight};
  border-radius: 80px;
  color: ${filterColors.text};
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.bold};
  text-align: center;
  cursor: pointer;

  &.excel {
    border: 2px solid ${filterColors.primary};
    background: transparent;
    color: ${filterColors.secondary};
  }

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const ExcelDownload = styled.img`
  height: ${sizes.iconSize};
  width: ${sizes.iconSize};
  margin: 0 ${spacing.small} 0 0;
`;
