import React from 'react';
import { Card } from '../../../../../styles/lib/cards';
import { Container, Content } from '../../../../../styles/lib/grid';
import { Topbar } from '../../../../../components';
import { LinkWrapper, TextLink } from '../../../../../styles/lib/buttons';

import AlliedLogo from '../../../../../assets/allied-empresas-logo.png';
import BackArrow from '../../../../../assets/back-arrow.svg';

export const DealerCompleteStep: React.FC = () => {
  return (
    <>
      <Container>
        <Topbar />
        <LinkWrapper>
          <img src={BackArrow} />
          <TextLink href="/">Voltar para login</TextLink>
        </LinkWrapper>
        <img src={AlliedLogo} />
        <Content>
          <Card className={`block animation-off`}>
            <h1 style={{ marginTop: '5%' }}>Validação realizada com sucesso</h1>
            <p style={{ marginTop: '3%', fontSize: '16px' }}>
              Seu cadastro está quase pronto!
            </p>
            <p style={{ marginTop: '1%', marginBottom: '5%' }}>
              Verifique a caixa de entrada no seu e-mail informado com o status
              da sua solicitação.
            </p>
          </Card>
        </Content>
      </Container>
    </>
  );
};
