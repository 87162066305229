import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ResponsiveRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  div {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
