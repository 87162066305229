import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
} from '../../../components';
import { Permissions } from '../../../pages/AccessControl/Permissions/Permissions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStorage } from '../../../hooks';
import { Container, Content } from '../../../styles/lib/grid';
import {
  TabBadge,
  TabSelector,
  TabsNavigation,
  TabsSelector,
} from '../../../styles/lib/tabs';
import { TitleContent } from '../../../styles/lib/typography';
import { Users } from '../Users/Users';

const TABS = {
  USERS: 'users',
  PERMISSIONS: 'permissions',
};

const PATHS = {
  PANEL: '/painel',
  ACCESS_CONTROL: '/controle-de-acesso/usuarios',
  USERS: '/controle-de-acesso/usuarios',
  PERMISSIONS: '/controle-de-acesso/perfis',
};

export const AccessTabNavigation: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.USERS);
  const [totalEmAnalise, setTotalEmAnalise] = useState<string | null>(null);
  const [paths, setPaths] = useState([
    {
      title: 'Meu Painel',
      link: PATHS.PANEL,
    },
    {
      title: 'Controle de Acesso',
      link: PATHS.USERS,
    },
  ]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const updatePaths = (title: string, link: string) => {
    setPaths([
      {
        title: 'Meu Painel',
        link: PATHS.PANEL,
      },
      {
        title: 'Controle de acesso',
        link: PATHS.ACCESS_CONTROL,
      },
      {
        title,
        link,
      },
    ]);
  };

  const { getItemOnStorage } = useStorage();
  const user = JSON.parse(getItemOnStorage('currentUser') || '');

  useEffect(() => {
    if (pathname.includes('usuarios')) {
      setActiveTab(TABS.USERS);
      updatePaths('Gestão de Usuários', PATHS.USERS);
    } else if (pathname.includes('perfis')) {
      setActiveTab(TABS.PERMISSIONS);
      updatePaths('Gestão de Perfis', PATHS.PERMISSIONS);
    } else {
      setActiveTab(TABS.USERS);
      updatePaths('Gestão de Usuários', PATHS.USERS);
    }
  }, [pathname]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    navigate(tabName === TABS.USERS ? PATHS.USERS : PATHS.PERMISSIONS);
  };

  return (
    <Container>
      <Topbar />
      <Navbar />
      <Content className="modify-content">
        <Breadcrumb paths={paths} />
        <TitleContent style={{ marginBottom: '3%' }}>
          Controle de Acesso
        </TitleContent>

        <TabsNavigation>
          <TabsSelector className="secondary">
            <TabSelector
              className={activeTab === TABS.USERS ? 'active' : ''}
              onClick={() => handleTabClick(TABS.USERS)}
            >
              Usuários
              <TabBadge className={activeTab === TABS.USERS ? '' : 'inactive'}>
                {totalEmAnalise || '-'}
              </TabBadge>
            </TabSelector>
            {user.perfil === 'administrador' && (
              <TabSelector
                className={activeTab === TABS.PERMISSIONS ? 'active' : ''}
                onClick={() => handleTabClick(TABS.PERMISSIONS)}
              >
                Perfis
              </TabSelector>
            )}
          </TabsSelector>
        </TabsNavigation>
      </Content>

      {activeTab === TABS.USERS ? (
        <Users setTotalEmAnalise={setTotalEmAnalise} />
      ) : null}
      {activeTab === TABS.PERMISSIONS ? <Permissions /> : null}

      <DashboardFooter />
    </Container>
  );
};
