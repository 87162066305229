import React, { useEffect, useState } from 'react';
import { Button } from '../../../../styles/lib/buttons';
import { Card } from '../../../../styles/lib/cards';
import { Content, FlexSpaceBetween } from '../../../../styles/lib/grid';
import {
  Progress,
  ProgressBarWrapper,
} from '../../../../styles/lib/progress-bar';
import {
  StepValidationService,
  validationRules,
} from '../Services/StepValidationService';

interface Step {
  title: string;
  description: string;
  form: React.ReactNode;
}

interface StepFormProps {
  steps: Step[];
}

export const DealerStepsForms: React.FC<StepFormProps> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isStepValid, setIsStepValid] = useState(false);

  useEffect(() => {
    const validateCurrentStep = () => {
      const isValid = StepValidationService.validateStep(
        currentStep,
        validationRules,
      );
      setIsStepValid(isValid);
    };

    validateCurrentStep();
    const interval = setInterval(validateCurrentStep, 500);
    return () => clearInterval(interval);
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <>
      <Card className={`block animation-off`}>
        <div
          style={{
            width: '100%',
            display: currentStep === 3 ? 'none' : 'inherit',
          }}
          className={`${currentStep === 3 ? 'flex' : ''}`}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <h2>{steps[currentStep].title}</h2>
            <p className="size16">{steps[currentStep].description}</p>
            {currentStep !== 3 && (
              <div style={{ marginTop: '30px' }}>{steps[currentStep].form}</div>
            )}
          </div>
        </div>
        {currentStep === 3 && (
          <div
            style={{
              width: '100%',
            }}
          >
            {steps[currentStep].form}
          </div>
        )}
      </Card>

      <Content className="padding-off">
        <FlexSpaceBetween style={{ marginTop: '3%' }}>
          <div>
            <p>
              Etapa {currentStep + 1} de {steps.length}
            </p>
            <ProgressBarWrapper>
              <Progress progress={progress} />
            </ProgressBarWrapper>
          </div>
          {currentStep !== 3 && (
            <Button
              className="auto-width mobile-block"
              onClick={nextStep}
              style={{ minWidth: '200px' }}
              disabled={!isStepValid}
            >
              Próximo
            </Button>
          )}
        </FlexSpaceBetween>
      </Content>
    </>
  );
};
